<template>
  <div class="row mx-0 justify-content-center" @click="interacted">
    <div class="col-12 col-sm-10 col-lg-9 col-xl-8 card_holder p-0 bg">
      <div class="background"></div>
      <div class="row mx-0 py-md-4 px-md-3 py-3 px-0 above_z h-100">
        <SelectQuantity v-if="activeProject && activeProject.fixed" v-model="quantity" @updateQuantity="updateQuantity" />
        <EnterAmount v-model="donation.amount" v-else :isAdded="isAdded" />
        <ShowTotal v-if="activeProject && activeProject.fixed" :displayTotalAndCurrency="displayTotalAndCurrency" />
        <SelectProjectOptions v-model:selectedProject="selectedProject" v-model:donationType="donationType" :visiblePosters="visiblePosters" :projectWithTypes="projectWithTypes" :currency="defaultCurrency" />
        <TheButtons :isAdded="isAdded" :saveDisabled="saveDisabled" @donate="goDonate" @addToCart="addDonationToCart" />
      </div>
    </div>
    <transition name="comeIn">
      <AddCartPopup v-if="isAdded" @close="isAdded = false"/>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    EnterAmount: defineAsyncComponent(() => import('./views/EnterAmount.vue')),
    SelectQuantity: defineAsyncComponent(() => import('./views/SelectQuantity.vue')),
    SelectProjectOptions: defineAsyncComponent(() => import('./views/SelectProjectOptions.vue')),
    ShowTotal: defineAsyncComponent(() => import('./views/ShowTotal.vue')),
    TheButtons: defineAsyncComponent(() => import('./views/TheButtons.vue')),
    AddCartPopup: defineAsyncComponent(() => import('@/views/AddCartPopup.vue'))
  },
  name: 'Quick Donate Home',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isAdded: false,
      quantity: 1,
      selectedProject: {},
      donation: {
        amount: '',
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      },
      donationType: '',
      projectWithTypes: {}
    }
  },
  watch: {
    activeSlider () {
      if (this.activeSlider) {
        this.selectedProject = { ...this.activeSlider }
      } else {
        this.selectedProject = {}
      }
    },
    selectedProject () {
      if (this.selectedProject && Object.keys(this.selectedProject).length > 0 && this.selectedProject.project && this.selectedProject.project.adminProjectId) {
        this.setDefaults(this.selectedProject.project)
        this.donation.projectId = this.selectedProject.project.adminProjectId
      }
      this.setActiveProject(this.selectedProject)
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects',
      'activeSlider',
      'visiblePosters'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    activeProject () {
      if (this.activeSlider && this.activeSlider.project) {
        const ret = this.adminProjects.filter(project => {
          if (project.projectID !== this.activeSlider.project.adminProjectId) {
            return false
          }
          return true
        })
        return ret[0]
      } else {
        return false
      }
    },
    displayTotalAndCurrency () {
      return `${this.donation.currency} ${parseFloat(Math.round(this.donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
    },
    saveDisabled () {
      if (this.donation.amount <= 0 || Object.keys(this.selectedProject).length === 0 || !this.donationType) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    this.setInteracted(false)
    this.donation.currency = this.defaultCurrency
  },
  methods: {
    ...mapActions([
      'addToCart',
      'clearCart',
      'setActiveProject',
      'setInteracted'
    ]),
    goDonate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    interacted () {
      this.setInteracted(true)
    },
    async addDonationToCart () {
      await this.projectWithTypes.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: this.donationType, amount: this.donation.amount })
        }
      })
      await this.addToCart(this.donation)
      this.isAdded = true
      this.quantity = 1
      this.donation = {
        amount: '',
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      }
      await this.setDefaults(this.selectedProject.project)
      this.donation.projectId = this.selectedProject.project.adminProjectId
    },
    updateQuantity () {
      if (this.activeProject && this.activeProject.fixed) {
        this.donation.amount = this.quantity * this.activeProject.cost
      }
    },
    setDefaults (val) {
      this.projectWithTypes = this.adminProjects.find(project => project.projectID === val.adminProjectId)
      if (this.projectWithTypes) {
        this.defaultDonationType(this.projectWithTypes)
        this.defaultPrice(this.projectWithTypes)
      } else {
        this.defaultPrice(this.projectWithTypes)
      }
    },
    defaultDonationType (val) {
      if (val && Object.keys(val).length > 0) {
        val.projectDonationTypeMaps.forEach((dType) => {
          if (dType.donationType.donationTypeID === 3) {
            this.donationType = dType
          }
        })
      }
    },
    defaultPrice (val) {
      if (val && (val.fixed || val.suggestedPrice)) {
        this.quantity = 1
        this.donation.amount = val.cost
      } else if (this.isUK) {
        this.donation.amount = 5
      } else {
        this.donation.amount = 100
      }
    }
  }
}
</script>

<style scoped>
.card_holder {
  border-radius: 22px;
}
.bg {
  background-image: linear-gradient(rgb(26, 104, 54) 11%, rgb(26, 104, 54) 93%);
  position: relative;
}
.background {
  position: absolute;
  background-color: rgb(26, 104, 54);
  background-image: url(../../../../assets/images/bg.png);
  opacity: 0.05;
  border: none;
  width: 100%;
  height: 100%;
  min-height: 40px;
  padding: 0;
  z-index: 1;
  border-radius: 22px;
}
.above_z {
  position: relative;
  z-index: 2;
}
@media (min-width: 992px) {
  .background {
    background-size: 24%;
  }
}
</style>
